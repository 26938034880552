<template>
  <div>
    <div class="activity-title-row initial">
      <div class="activity-title pointer" @click="active = !active">
        Electricity WTT Treatment
      </div>
      <i class="fas fa-caret-down" :class="active ? 'active' : ''"></i>
    </div>
    <div v-if="active" class="">
      <div class="input-section" style="max-width: 650px">
        <label style="cursor: pointer" class="input-container-row">
          This is a returning client, I confirm their electricity WTT based on
          their previous years measurement, was Material (>1%) Check box to
          confirm old WTT EF to be applied.
          <input
            class="big"
            type="checkbox"
            v-model="$store.state.app.report.previousElectricityWTTWasMaterial"
            v-on:change="
              updateElectricityWTTRule(
                $store.state.app.report.previousElectricityWTTWasMaterial
                  ? 'show'
                  : 'hide'
              );
              updateReport('previousElectricityWTTWasMaterial');
            "
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </label>
        <label class="input-container-row"
          >Disclaimer: The outdated EF will be added to the report when this box
          is ticked.</label
        >
      </div>
      <div class="section-title">Previous Year's Electricity</div>
      <div class="input-section">
        <table>
          <tr>
            <th>Emissions Source</th>
            <th>Annual Total</th>
            <th>tC02e (Output)</th>
            <th>% Of Inventory</th>
            <th>tCO2e (Market Rate)</th>
            <th>% of Inventory Market Rate</th>
          </tr>
          <tr
            v-for="source in rawEmissionSources"
            :key="source['Emissions source']"
          >
            <td>{{ source["Emissions source"] }}</td>
            <td>{{ source["Annual Total Input"] }}</td>
            <td>{{ source["tCO2e (Output)"] }}</td>
            <td>{{ source["percentageOfInventory"] }}</td>
            <td>{{ source["tCO2e (Market Rate)"] }}</td>
            <td>{{ source["percentageOfInventoryMR"] }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import emissionsMixinVue from '../../mixins/emissionsMixin.vue'
export default {
  mixins: [emissionsMixinVue],
  data: () => ({
    active: false
  }),
  methods: {
    async updateReport (field, loadAfterSaving = false) {
      const data = { report: { id: this.$store.state.app.report.id } }
      data.report[field] = this.$store.state.app.report[field]
      data.load = loadAfterSaving
      this.$root.saving = true
      await this.$store.dispatch('updateReport', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    updateElectricityWTTRule (rule) {
      const electricitySchema =
        this.$root.masterSchema['scopeTwoSchemas'][0][0]
      electricitySchema.emissionSources.forEach((es) => {
        if (es.type === 'WTT') es.rule = rule
      })
      this.$root.saveData()
    }
  },
  computed: {
    rawEmissionSources () {
      const emissionsSources = this.$store.state.app.client.previousYear.emissionsSources.nodes
      const totals = this.getTotalsFromEmissionsSources(emissionsSources)
      return emissionsSources.filter(es => es.name.includes('Electricity')).map(es => {
        return {
          'Emissions source': es.name,
          'Annual Total Input': es.annualTotal,
          'tCO2e (Output)': es.tCO2e,
          percentageOfInventory: this.getPercentage(es.tCO2e, totals.emissions),
          'tCO2e (Market Rate)': es.tCO2eMarketRate,
          percentageOfInventoryMR: this.getPercentage(es.tCO2eMarketRate, totals.emissionsMR)
        }
      }).sort((a, b) => b['tCO2e (Output)'] - a['tCO2e (Output)'])
    }
  }
}
</script>

<style>
</style>
